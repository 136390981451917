import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useCities } from '~/store/cites/cities.store'

export function setCompare() {
  const cityStore = useCities()
  const tariffsStore = useTariffs()
  const currentCity = computed(() => cityStore.getCity)

  const compareListTariffs = computed(
    () => tariffsStore.getCompareList[currentCity.value?.fias_id] || [],
  )

  const addCompare = (id: string, name: string) => {
    const compareList = compareListTariffs.value

    if (!compareList.includes(id)) {
      tariffsStore.notifArr.splice(0, 5, { name: name, add: true, id: id })
      tariffsStore.setCompare(id)
    }
    else {
      tariffsStore.notifArr.splice(0, 5, { name: name, add: false, id: id })
      tariffsStore.setCompare(id)
    }
  }

  return {
    compareListTariffs,
    addCompare,
  }
}
